import React, { useState, useEffect } from "react";
import { Payments } from "../../../axiosConfig";
import Countdown from "react-countdown";
import { Navigate, useNavigate } from "react-router-dom";

const WorkshopBottomBanner = ({ bottomBanner }) => {
    const navigate = useNavigate();
    const [workshop, setWorkshop] = useState(null);

    useEffect(() => {
        Payments.get("api/v1/offline-workshops/workshops/")
            .then((response) => {
                const activeWorkshop = response.data.find(
                    (workshop) => workshop.is_active
                );
                if (activeWorkshop) {
                    setWorkshop(activeWorkshop);
                }
            })
            .catch((error) => {
                console.error("Error fetching workshop:", error);
            });
    }, []);

    if (!workshop) {
        return <div className="bottom-banner">Loading...</div>;
    }

    const { title, start_date } = workshop;
    const startDate = new Date(start_date);
    const formattedDate = startDate.toISOString().split("T")[0];

    return (
        <div className={`bottom-banner-workshop ${bottomBanner ? "show" : ""}`}>
            <div className="content">
                <p className="date">
                    Workshop Start on <strong>{formattedDate}</strong>
                </p>
                <h2>{title}</h2>
            </div>
            <div className="date-content">
                <Countdown
                    date={startDate}
                    renderer={({ days, hours, minutes, seconds }) => (
                        <div className="countdown">
                            <div className="time-content">
                                <div className="main-time">
                                    <div className="time-box">
                                        <span>
                                            {String(days).padStart(2, "0")}
                                        </span>
                                    </div>
                                    <span>:</span>
                                </div>
                                <p className="time-text">DAYS</p>
                            </div>
                            <div className="time-content">
                                <div className="main-time">
                                    <div className="time-box">
                                        <span>
                                            {String(hours).padStart(2, "0")}
                                        </span>
                                    </div>
                                    <span>:</span>
                                </div>
                                <p className="time-text">HOURS</p>
                            </div>
                            <div className="time-content">
                                <div className="main-time">
                                    <div className="time-box">
                                        <span>
                                            {String(minutes).padStart(2, "0")}
                                        </span>
                                    </div>
                                    <span>:</span>
                                </div>
                                <p className="time-text">MINUTES</p>
                            </div>
                            <div className="time-content">
                                <div className="main-time">
                                    <div className="time-box">
                                        <span>
                                            {String(seconds).padStart(2, "0")}
                                        </span>
                                    </div>
                                    {/* <span>:</span> */}
                                </div>
                                <p className="time-text">SECONDS</p>
                            </div>
                        </div>
                    )}
                />
                <div>
                    <button
                        className="register-btn button-effect-primary"
                        onClick={(event) => navigate("/workshop")}
                    >
                        Register Now
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WorkshopBottomBanner;
